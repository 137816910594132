import Swiper, { Pagination, A11y, Autoplay, EffectFade, Thumbs } from 'swiper';

Swiper.use([Pagination, A11y, Autoplay, EffectFade, Thumbs]);

export function indexMainSliderInit(next) {
  const topSwiperEl = next.querySelector('.main-visual--slider');
  const topSwiperPagenationEl = next.querySelector(
    '.main-visual--slider--pagination'
  );
  var mySwiper = new Swiper(topSwiperEl, {
    slideClass: 'main-visual--slider--list--item',
    wrapperClass: 'main-visual--slider--list',
    effect: 'fade',
    fadeEffect: {
      crossFade: true,
    },
    loop: true,
    autoplay: true,
    delay: 8000,
    speed: 4000,

    // If we need pagination
    pagination: {
      el: topSwiperPagenationEl,
      clickable: true,
      type: 'bullets',
    },
  });
}

export function projectsSliderInit(next) {
  const projectsThumbSwiperEl = next.querySelector('.projects--thumb-slider');
  const projectsSwiperEl = next.querySelector('.projects--slider');
  var thumbsSwiper = new Swiper(projectsThumbSwiperEl, {
    slideClass: 'projects--thumb-slider--list--item',
    wrapperClass: 'projects--thumb-slider--list',
    slidesPerView: 'auto',
    setWrapperSize: true,
    freeMode: true,
    spaceBetween: 16,
  });
  var mySwiper = new Swiper(projectsSwiperEl, {
    slideClass: 'projects--slider--list--item',
    wrapperClass: 'projects--slider--list',
    effect: 'fade',
    fadeEffect: {
      crossFade: true,
    },
    speed: 2000,
    thumbs: {
      swiper: thumbsSwiper,
    },
  });
}
