import anime from 'animejs/lib/anime.es.js';

export function abstractAnime() {
  const animation = anime({
    targets: document.querySelectorAll('.abstract--circle'),
    loop: true,
    easing: 'linear',
    rotate: 360,
    duration: 24000,
  });
}

export function enterIndexAnime(next) {
  const animation = anime.timeline().add({
    easing: 'cubicBezier(0.000, 0.320, 0.380, 1.000)',
    targets: next.container.querySelector('.transition-wrapper'),
    duration: 700,
    opacity: [0, 1],
  });
  return animation.finished;
}

export function leaveIndexAnime(next) {
  const animation = anime.timeline().add({
    easing: 'cubicBezier(0.000, 0.320, 0.380, 1.000)',
    targets: next.container.querySelector('.transition-wrapper'),
    duration: 700,
    opacity: [1, 0],
  });
  return animation.finished;
}

export function leaveProjectsToProjects(next) {
  const animation = anime
    .timeline()
    .add({
      easing: 'cubicBezier(0.000, 0.320, 0.380, 1.000)',
      targets: next.container.querySelector('.projects--header .title--sub'),
      duration: 700,
      opacity: [1, 0],
    })
    .add({
      easing: 'cubicBezier(0.000, 0.320, 0.380, 1.000)',
      targets: next.container.querySelector('.projects--list'),
      duration: 700,
      opacity: [1, 0],
    });
  return animation.finished;
}

export function enterProjectsToProjects(next) {
  const animation = anime
    .timeline()
    .add({
      easing: 'cubicBezier(0.000, 0.320, 0.380, 1.000)',
      targets: next.container.querySelector('.projects--header .title--sub'),
      duration: 700,
      opacity: [0, 1],
    })
    .add({
      easing: 'cubicBezier(0.000, 0.320, 0.380, 1.000)',
      targets: next.container.querySelector('.projects--list'),
      duration: 700,
      opacity: [0, 1],
    });
  return animation.finished;
}

export function leaveNewsToNews(next) {
  const animation = anime
    .timeline()
    .add({
      easing: 'cubicBezier(0.000, 0.320, 0.380, 1.000)',
      targets: next.container.querySelector('.projects--header .title--l'),
      duration: 700,
      opacity: [1, 0],
    })
    .add({
      easing: 'cubicBezier(0.000, 0.320, 0.380, 1.000)',
      targets: next.container.querySelector('.news-and-think--list'),
      duration: 700,
      opacity: [1, 0],
    });
  return animation.finished;
}

export function enterNewsToNews(next) {
  const animation = anime
    .timeline()
    .add({
      easing: 'cubicBezier(0.000, 0.320, 0.380, 1.000)',
      targets: next.container.querySelector('.projects--header .title--l'),
      duration: 700,
      opacity: [0, 1],
    })
    .add({
      easing: 'cubicBezier(0.000, 0.320, 0.380, 1.000)',
      targets: next.container.querySelector('.news-and-think--list'),
      duration: 700,
      opacity: [0, 1],
    });
  return animation.finished;
}

export function loadNextPosts(items) {
  const animation = anime.timeline().add({
    easing: 'easeInOutQuad',
    targets: items,
    duration: 500,
    translateY: [50, 0],
    opacity: [0, 1],
    delay: function (el, i, l) {
      return i * 100;
    },
  });
}
