var InfiniteScroll = require('infinite-scroll');

// spボタンをクリックでメニューを開く

const elBtn = document.querySelector('.header--nav--button');
const elList = document.querySelector('.header--nav--list');
const addOverlay = (parentElClass) => {
  const overlayInit = document.createElement('div');
  overlayInit.setAttribute('class', 'overlay-nav');
  overlayInit.setAttribute('aria-hidden', 'true');
  const parentEl = document.querySelector(parentElClass);
  parentEl.appendChild(overlayInit);
};

export function openNavMenu() {
  addOverlay('.content-body');
  const overlay = document.querySelector('.overlay-nav');
  elBtn.addEventListener('click', () => {
    if (elBtn.classList.contains('open')) {
      elBtn.classList.remove('open');
      elBtn.classList.add('close');
      elBtn.setAttribute('aria-label', 'メニューを閉じる');
      elList.classList.add('open');
      elList.setAttribute('aria-hidden', 'false');
      overlay.classList.add('navigation-open');
    } else {
      closeNavMenu();
    }
  });
  overlay.addEventListener('click', () => {
    closeNavMenu();
  });
}

export function closeNavMenu() {
  const overlay = document.querySelector('.overlay-nav');
  if (elList.classList.contains('open')) {
    elBtn.classList.remove('close');
    elBtn.classList.add('open');
    elBtn.setAttribute('aria-label', 'メニューを開く');
    elList.classList.remove('open');
    elList.setAttribute('aria-hidden', 'true');
    overlay.classList.remove('navigation-open');
    overlay.classList.add('navigation-close');
    setTimeout(function () {
      overlay.classList.remove('navigation-close');
    }, 400);
  }
}

export function accordionMenu() {
  const elBtnDrops = document.querySelectorAll('.link-accordion');

  elBtnDrops.forEach((el) => {
    const elNext = el.nextElementSibling;
    elNext.style.height = 'auto';
    var elNextH = elNext.clientHeight;
    console.log(elNextH);
    elNext.style.height = '0px';
    elNext.classList.add('close');

    el.addEventListener('click', () => {
      if (elNext.classList.contains('close')) {
        el.classList.add('open');
        el.setAttribute('aria-expanded', 'true');
        elNext.classList.remove('close');
        elNext.classList.add('open');
        elNext.setAttribute('aria-hidden', 'false');
        elNext.style.height = elNextH + 'px';
      } else {
        el.classList.remove('open');
        el.setAttribute('aria-expanded', 'false');
        elNext.classList.remove('open');
        elNext.classList.add('close');
        elNext.setAttribute('aria-hidden', 'true');
        elNext.style.height = '0px';
      }
    });
  });
}

export function articleFilter(next) {
  const elBtnFilter = next.querySelectorAll(
    '.article--filter--list--item--btn'
  );
  const overlay = next.querySelector('.overlay.close');

  for (const el of elBtnFilter) {
    const elNext = el.nextElementSibling;

    el.addEventListener('click', () => {
      if (elNext.classList.contains('close')) {
        overlay.classList.remove('close');
        overlay.classList.add('open');
        const eachDrop = next.querySelectorAll(
          '.article--filter--list--dropdown'
        );
        for (const el of eachDrop) {
          el.classList.remove('open');
          el.setAttribute('aria-hidden', 'false');
          el.classList.add('close');
        }
        const eachDropBtn = next.querySelectorAll(
          '.article--filter--list--item--btn'
        );
        for (const el of eachDropBtn) {
          el.classList.remove('open');
          el.classList.add('close');
        }
        el.classList.add('open');
        el.setAttribute('aria-expanded', 'true');
        elNext.classList.remove('close');
        elNext.classList.add('open');
      } else {
        overlay.classList.remove('open');
        el.classList.remove('open');
        el.setAttribute('aria-expanded', 'false');
        elNext.classList.remove('open');
        elNext.classList.add('close');
        elNext.setAttribute('aria-hidden', 'true');
      }
    });
    overlay.addEventListener('click', () => {
      overlay.classList.remove('open');
      el.classList.remove('open');
      elNext.classList.remove('open');
      elNext.classList.add('close');
    });
  }
}

//ナビゲーションのカレント表示
export function navActive() {
  const path = location.pathname;
  const pathFirst = path.split('/');
  const hNavLink = document.querySelectorAll('.header--nav--list--item--link');
  for (const el of hNavLink) {
    el.classList.remove('current');
    const elHref = el.getAttribute('href');
    const hrefName = elHref.split('/');
    if (pathFirst[1] === hrefName[1]) {
      el.classList.add('current');
    }
  }
}

export function snsBtnPopup() {
  // サブウインドウ表示
  const el = document.querySelector('.opensub');
  el.addEventListener('click', () => {
    window.open(
      this.href,
      'WindowName',
      'width=520,height=520,resizable=yes,scrollbars=yes'
    );
    return false;
  });
}
