import 'core-js/stable';
import 'regenerator-runtime/runtime';

import './css/style.scss';
import barba from '@barba/core';
import Vue from 'vue';
import axios from 'axios';
var InfiniteScroll = require('infinite-scroll');
import * as swiperInit from './modules/swiper-init.js';
import * as nav from './modules/navigation.js';
import * as transition from './modules/animation.js';
import * as map from './modules/map.js';
import 'focus-visible';
import 'classlist-polyfill';

nav.openNavMenu();
nav.accordionMenu();
nav.navActive();

window.addEventListener('load', function () {
  const mainElement = document.querySelector('main');
  if (mainElement.classList.contains('project-achive')) {
    initInfinite();
  }
});

barba.hooks.enter((data) => {
  window.scrollTo(0, 0);
  nav.navActive();
  nav.closeNavMenu();
});

barba.init({
  prevent: ({ el }) => el.getAttribute('href').slice(0, 1) === '#',
  transitions: [
    {
      //index to local
      from: {
        namespace: ['top'],
      },
      to: {
        namespace: ['about', 'single', 'single-projects', 'contact'],
      },
      async leave({ current, next, trigger }) {
        //ページを離れる時のアニメーション
        await transition.leaveIndexAnime(current);
      },
      enter({ current, next, trigger }) {
        const el = document.querySelector('.global--header');
        el.classList.remove('index');
        transition.enterIndexAnime(next);
      },
    },
    {
      //local to index
      from: {
        namespace: [
          'about',
          'archive',
          'archive-filtered',
          'single',
          'single-projects',
          'contact',
        ],
      },
      to: {
        namespace: ['top'],
      },
      async leave({ current, next, trigger }) {
        //ページを離れる時のアニメーション
        await transition.leaveIndexAnime(current);
      },
      async afterLeave({ current, next, trigger }) {
        //enter時の初期表示状態を設定
      },
      async beforeEnter({ current, next, trigger }) {},

      enter({ current, next, trigger }) {
        transition.enterIndexAnime(next);
      },
    },
    {
      //local to local
      from: {
        namespace: [
          'about',
          'contact',
          'single',
          'single-projects',
          'archive',
          'archive-filtered',
        ],
      },
      to: {
        namespace: ['top', 'about', 'contact', 'single', 'single-projects'],
      },
      async leave({ current, next, trigger }) {
        //ページを離れる時のアニメーション
        await transition.leaveIndexAnime(current);
      },
      enter({ current, next, trigger }) {
        transition.enterIndexAnime(next);
      },
    },
    //to archive
    {
      from: {
        namespace: ['about', 'contact', 'single', 'single-projects'],
      },
      to: {
        namespace: ['archive', 'archive-filtered'],
      },
      async leave({ current, next, trigger }) {
        //ページを離れる時のアニメーション
        await transition.leaveIndexAnime(current);
      },
      enter({ current, next, trigger }) {
        transition.enterIndexAnime(next);
      },
      after(next) {
        initInfinite();
      },
    },
    {
      //index to archive
      from: {
        namespace: ['top'],
      },
      to: {
        namespace: ['archive', 'archive-filtered'],
      },
      async leave({ current, next, trigger }) {
        //ページを離れる時のアニメーション
        await transition.leaveIndexAnime(current);
      },
      enter({ current, next, trigger }) {
        const el = document.querySelector('.global--header');
        el.classList.remove('index');
        transition.enterIndexAnime(next);
      },
      after(next) {
        initInfinite();
      },
    },
    {
      //projects -> projects
      from: {
        namespace: ['archive', 'archive-filtered'],
      },
      to: {
        namespace: ['archive', 'archive-filtered'],
      },
      async leave({ current, next, trigger }) {
        //ページを離れる時のアニメーション
        await transition.leaveProjectsToProjects(current);
      },
      enter({ current, next, trigger }) {
        //ページを表示する時のアニメーション
        transition.enterProjectsToProjects(next);
      },
      after(next) {
        initInfinite(next);
      },
    },
  ],
  views: [
    {
      namespace: 'top',
      afterEnter(data) {
        const el = document.querySelector('.global--header');
        el.classList.add('index');
        swiperInit.indexMainSliderInit(data.next.container);
      },
    },
    {
      namespace: 'about',
      beforeEnter({ next }) {
        // prevent Google Map API script from being loaded multiple times
        if (typeof window.createMap === 'function') {
          window.createMap();
        } else {
          window.createMap = () => {
            map.initMap();
          };

          // load the Google Map API script
          let script = document.createElement('script');
          script.src =
            'https://maps.googleapis.com/maps/api/js?key=AIzaSyDG-WCSF5v-cJFu69j-w-kXLM_CKvFPbog&callback=createMap';
          next.container.appendChild(script);
        }
      },
    },
    {
      namespace: 'archive',
      afterEnter(data) {
        nav.articleFilter(data.next.container);
      },
    },
    {
      namespace: 'archive-filtered',
      afterEnter(data) {
        nav.articleFilter(data.next.container);
      },
    },
    {
      namespace: 'single-projects',
      afterEnter(data) {
        swiperInit.projectsSliderInit(data.next.container);
      },
    },
    {
      namespace: 'contact',
      afterEnter(data) {
        // Contact Form
        new Vue({
          el: '#postForm',
          data: {
            // フォームの表示
            showForm: true,
            // お名前
            userName: '',
            // メールアドレス
            email: '',
            // ご依頼内容
            content: '',
          },
          methods: {
            submit: function () {
              const submitParams = new FormData();
              // お名前のname属性値
              submitParams.append('entry.801427369', this.userName);
              // メールアドレスのname属性値
              submitParams.append('entry.358225192', this.email);
              // ご依頼内容のname属性値
              submitParams.append('entry.2023439417', this.content);
              // Googleフォームのaction属性値
              const GOOGLE_FORM_ACTION =
                'https://docs.google.com/forms/u/0/d/e/1FAIpQLSdtCbybk-6R4rykDo-pf8owGUXnY4PxleZ3X08apHJ-sDCLuw/formResponse';

              // Ajax POST通信
              axios.post(GOOGLE_FORM_ACTION, submitParams).then((res) => {
                // フォーム非表示
                this.showForm = false;
              });
              // フォーム非表示
              this.showForm = false;
            },
          },
        });
      },
    },
  ],
});

function initInfinite() {
  var list_wrapper = '.projects';
  var nextLinkEl = document.querySelector(list_wrapper + ' .previous_link a');
  if (!(nextLinkEl == null)) {
    var infScroll = new InfiniteScroll(list_wrapper + ' .projects--list', {
      // options
      path: list_wrapper + ' .previous_link a',
      append: list_wrapper + ' .projects--list--item',
      status: list_wrapper + ' .page-load-status',
      history: false,
      prefill: true,
    });
    infScroll.on('append', function (response, path, items) {
      transition.loadNextPosts(items);
    });
    infScroll.on('last', function (response, path) {
      document.querySelector(list_wrapper + ' .page-load-status').remove();
    });
  }
}